// dragging item 
.sortable-drag {
  opacity: 1 !important;
  .sortable-item {
    transform: rotate(-5deg);
    // background-color: var(--#{$prefix}drag-bg);
    top: 5px;
  }
}

// create drop placeholder
.sortable-ghost {
  .sortable-item {
    position: relative;

    &::before {
      content: '';
      height: 100%;
      width: 100%;
      background-color: var(--#{$prefix}ghost-bg);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1000;
      border-radius: $border-radius;
    }
  }
}

//
.sortable-dragging * {
  cursor: grabbing !important;
}

.sortable-item {
  cursor: pointer;
  &:active {
    cursor: grabbing;
  }
}