.accordion {
  --#{$prefix}accordion-color: #{$accordion-color};
}

.accordion-button {
  font-weight: $font-weight-bold;
  &.collapsed {
    color: var(--#{$prefix}primary);
  }
}

.accordion-item {
  border-bottom: 1px solid var(--#{$prefix}gray-300);
  padding: map-get($spacers, 2);
}

.standard-accordion {
  .accordion-button {
    padding: map-get($spacers, 2) 0;
    color: $dark;
    // padding-left: map-get($spacers, 4);

    &::before {
      content: '';
      width: 1.25rem;
      height: 1.25rem;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%233874FF' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-plus-circle'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cline x1='12' y1='8' x2='12' y2='16'%3E%3C/line%3E%3Cline x1='8' y1='12' x2='16' y2='12'%3E%3C/line%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: 1.25rem;
      transition: transform 0.2s ease-in-out;
      margin-right: map-get($spacers, 2);
    }
    &::after {
      display: none;
    }

    &:not(.collapsed)::before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%233874FF' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-minus-circle'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cline x1='8' y1='12' x2='16' y2='12'%3E%3C/line%3E%3C/svg%3E");
      transform: rotate(-180deg);
    }
  }

  .accordion-body {
    padding-top: map-get($spacers, 3);
    padding-bottom: map-get($spacers, 3);
    padding-left: map-get($spacers, 4);
    margin-left: map-get($spacers, 1);
  }

  .accordion-item {
    border-bottom: none;
  }
}

.iconic-accordion {
  .accordion-button {
    color: $dark;
    padding: map-get($spacers, 2) 0;
    margin: map-get($spacers, 1) 0;

    &::before {
      content: '';
      width: 3rem;
      height: 3rem;
      padding: 4px;
      border-radius: 50%;
      background: var(--#{$prefix}primary);
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' fill='none' stroke='%233874FF' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-plus-circle'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cline x1='12' y1='8' x2='12' y2='16'%3E%3C/line%3E%3Cline x1='8' y1='12' x2='16' y2='12'%3E%3C/line%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: 1.25rem;
      background-position: center;
      transition: transform 0.2s ease-in-out;
      margin-right: map-get($spacers, 2);
    }

    &:not(.collapsed)::before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%233874FF' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-minus-circle'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cline x1='8' y1='12' x2='16' y2='12'%3E%3C/line%3E%3C/svg%3E");
      transform: rotate(-180deg);
    }

    &::after {
      background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='-200 -200 900 900'%3E%3Cpath fill='%233874FF' d='M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z'%3E%3C/path%3E%3C/svg%3E");
    }

    &:not(.collapsed)::after {
      background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='-200 -200 900 900'%3E%3Cpath fill='%233874FF' d='M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z'%3E%3C/path%3E%3C/svg%3E");
    }
  }

  .accordion-body {
    padding-top: map-get($spacers, 3);
    padding-bottom: map-get($spacers, 3);
    padding-left: map-get($spacers, 7);
    margin-left: map-get($spacers, 2);
  }

  .accordion-item {
    border-bottom: none;
  }
}

.dot-accordion {
  .accordion-button {
    padding: map-get($spacers, 2) 0;

    &::before {
      content: '';
      width: 0.375rem;
      height: 0.375rem;
      border-radius: 50%;
      background-color: var(--#{$prefix}gray-400);
      margin-right: map-get($spacers, 2);
    }

    &::after {
      display: none;
    }
  }

  .accordion-item {
    border-bottom: none;
  }
}
